import React, { Component } from 'react';
import {Link} from 'gatsby'
export default class VerifyUserCode extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	codes: this.props.codes,
	  	code: '',
		  showInstructions: false,
		  premiumInfo: false,
	  };
	}
	handleCodeInput({target}){
		this.setState({
			code: target.value,
		})
	}
	togglePremiumInfo(){
		let status = this.state.premiumInfo;
		this.setState({
			premiumInfo: !status,
		})
	}
	handleShowInstructions(){
		const currentStatus = this.state.showInstructions;
			this.setState({
				showInstructions: !currentStatus,
			})
		
	}
	componentDidMount(){
		this.setState({
			codes: this.props.codes,
		})
	}
	static getDerivedStateFromProps(props, state) {
		//
		return null
    }
	handleSkip(){
		this.props.handleSkip();
	}
	handleInfo(e){
		let button = e.target;
		let isOpen = button.getAttribute('data-showinfo');
		if(isOpen === 'false'){
			this.setState({
				infoMsg: 'A verification code unlocks all the tools you need to succeed.  This can be found on your latest order Receipt.  If you do not have a verification code right now, not to worry, one can always be added to your account later',
			})
		}
		else{
			this.setState({
				infoMsg: '',
			})
		}
	}
	verifyUser(){
		this.props.verifyUser(this.state.code)
	}
	render() {
		let showInstructions = this.state.showInstructions === true;
		let showPremiumInfo = this.state.premiumInfo === true;
		return(        
	        <div className="p-10 rounded-lg shadow-lg clearfix bg-white max-w-md mx-auto">
    		{showInstructions?
				<div classname="signupInstructions">
					<div  className="mb-4">
						<button className="w-full grey py-2 px-4 rounded-lg my-2" onClick={this.handleShowInstructions.bind(this)}>
							<span className="flaticon-left-arrow mr-auto"></span> Go Back
						</button>
					</div>
					<h2 className="text-2xl font-medium text-primary my-4 text-center">Unlock Our<br/>Premium Tools</h2>
					<p><strong>Sign in to unlock incredible online tools to make this the easiest weight management programme you have ever used! </strong></p>
					<p>Once you have signed in you will find fully customisable meal plans, shopping lists, a keto calculator and tracking tools, <strong>just a click away</strong>.</p>
					<strong>
						<p>Purchase your 2 or 4 week programme and automatically receive your verification code.</p>
						<p></p>
						<p><Link to="/contact" className="py-2 px-4 w-full rounded-lg bg-primary text-white inline-block">Click here to find your closest office ...</Link></p>
					</strong>
					
	            </div>
			:
			<div>
				<h1 className="text-secondary text-2xl text-center">Sign up</h1>
				<p className="text-center">Signup and you have access to all of our premium features</p>
				<div className="inputWrapper">
					<div className="text-center">
						<div className="inline-block text-center mx-auto">
							<div className="flex items-center text-center text-secondary">
								<label className="font-medium text-sm text-center mr-2" htmlFor="code">Premium Code</label>
								<button onClick={this.togglePremiumInfo.bind(this)} className="h-8 w-8 flex items-center border shadow-sm hover:shadow-md transition transition-all duration-300" style={{borderRadius: '50%'}}>
									<span aria-label="Where can i find this?" aria-required="true" aria-labelledby="Where can i find this?" className="text-1xl w-full text-center font-bold">?</span>
								</button>
							</div>
						</div>
					</div>
					{showPremiumInfo&&
					<div className="fixed h-full w-full top-0 left-0">
						<button className="flex w-full h-full items-center bg-black bg-opacity-75" onClick={this.togglePremiumInfo.bind(this)}>
							<div className="max-w-sm bg-white rounded-lg shadow-lg border border-rounded p-5 mx-auto text-center">
								<h3 className="text-2xl mx-5 leading-tight text-primary font-bold mb-5">Where do I find a premium code?</h3>
								<p>
									To receive a premium code you must purchase the Alpha Lipid SDII 2 or 4 week pack.
									Your premium code will arrive with your New Image International products
								</p>
								<button className="bg-secondary text-white rounded-lg py-2 w-full font-bold" onClick={this.togglePremiumInfo.bind(this)}>
									Close
								</button>
							</div>
						</button>
					</div>
					}
					<input type="text" 
						className="formControl my-4" 
						onChange={this.handleCodeInput.bind(this)} 
						value={this.state.code} 
						placeholder="Enter Code"
						name="code"
						id="code"
					/>
					
				</div>
				<div className="text-red-600 font-bold text-center mb-5">{this.props.verificationMessage}</div>
				<div className="inputWrapper">
					<button onClick={this.verifyUser.bind(this)} className="bg-primary rounded-lg py-2 px-4 text-white w-full font-bold">Verify Code</button>
				</div>
				<div className="inputWrapper">
					<Link className="bg-primary text-white rounded-lg py-2 px-4 w-full inline-block text-center font-bold" to="/free-trial">
						Start Your 14 Day Free Trial
					</Link>
				</div>
				<div className="inputWrapper">
					<button className="grey text-secondary py-2 w-full shadow-sm rounded-lg font-bold" onClick={this.handleShowInstructions.bind(this)}>
					<span className="flaticon-doubt"></span> What does premium offer?</button>
				</div>
				
				<p>{this.state.infoMsg}</p>
			</div>
		}
			</div>
				
			
		)
		
	}
}
